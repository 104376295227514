const processoOptions = [
  {
    label: 'Cambio fornitura',
    subtext: 'Voglio cambiare fornitore e passare ad A2A Energia.',
    value: 'cambio_fornitore',
    selected: false,
  },
  {
    label: 'Voltura',
    subtext: `Voglio cambiare l'intestazione del contratto, senza interruzione di servizio.`,
    value: 'voltura',
    selected: false,
  },
  {
    label: 'Nuova attivazione',
    subtext: 'Voglio attivare per la prima volta il mio contatore',
    value: 'prima_attivazione_contatore',
    selected: false,
  },
]

export const configSteps = [
  {
    name: 'commodity',
    step: 0,
    title: 'Cosa stai cercando?',
    options: [
      {
        label: 'Luce',
        subtext: '',
        value: 'luce',
        selected: false,
      },
      {
        label: 'Gas',
        subtext: '',
        value: 'gas',
        selected: false,
      },
      {
        label: 'Luce e Gas',
        subtext: '',
        value: 'dual',
        selected: false,
      },
    ],
    next: 'processo_$value$',
    confirmed: true,
  },
  {
    name: 'processo_luce',
    step: 1,
    title: 'Cosa devi fare?',
    options: processoOptions,
    next: 'prezzo_fisso',
    confirmed: false,
  },
  {
    name: 'processo_gas',
    step: 1,
    title: 'Cosa devi fare?',
    options: processoOptions,
    next: 'prezzo_fisso',
    confirmed: false,
  },
  {
    name: 'processo_dual',
    step: 1,
    title: 'Cosa devi fare?',
    options: processoOptions,
    next: 'prezzo_fisso',
    confirmed: false,
  },
  {
    name: 'prezzo_fisso',
    step: 2,
    title: 'Fissa o variabile?',
    options: [
      {
        label: 'Fissa',
        subtext: "Voglio un'offerta con il prezzo dai costi prevedibili",
        value: '',
        selected: false,
        next: 'durata',
      },
      {
        label: 'Variabile',
        subtext:
          "Voglio un'offerta con il prezzo che si adegua all'andamento del mercato",
        value: 'variabile',
        selected: false,
        next: 'vas',
      },
    ],
    next: null,
    confirmed: false,
  },
  {
    name: 'durata',
    step: 3,
    title: 'Quanto vuoi che duri l’offerta?',
    options: [
      {
        label: '1 anno',
        subtext:
          'Voglio avere la libertà di cambiare offerta in base alle mie necessità del momento',
        value: '1_anno',
        selected: false,
      },
      {
        label: '10 anni',
        subtext: "Voglio avere la tranquillità di un'offerta a lungo termine",
        value: '10_anni',
        selected: false,
      },
    ],
    next: null,
    confirmed: false,
  },
  {
    name: 'vas',
    step: 3,
    title: 'Vuoi arricchire la tua offerta?',
    options: [
      {
        label: 'Sì, con assistenza casa',
        subtext: 'Cerco un servizio di manutenzione dedicato',
        value: 'si',
        selected: false,
      },
      {
        label: 'No!',
        subtext: 'Desidero attivare solo un contratto di fornitura',
        value: 'no',
        selected: false,
      },
    ],
    next: null,
    confirmed: false,
  },
]
