import {
  StyledCommodityConfig,
  StyledCommodityConfigInner,
  StyledCommodityConfigWrapper,
} from './CommodityConfig.style'
import { CommodityConfigMain } from './components/CommodityConfigMain'
import { Loader } from 'components/RicaricaForm/components/Loading'
import { useEffect, useState } from 'react'
import { consoleLog, removeTrailingSlashes } from 'commons/utils'

export type ConfiguratorComponentOffertType = {
  prezzo_fisso: string | null
  processo_gas: string | null
  processo_luce: string | null
  tipologia_cliente: string | null
  vas: string | null
  status: boolean | null
  link: string | null
  label: string | null
  commodity: string | null
  [key: string]: string | number | boolean | null
}

export const CommodityConfig = ({
  endpointUrl,
}: {
  endpointUrl?: string | null
}) => {
  const [offerteData, setOfferteData] = useState<
    ConfiguratorComponentOffertType[]
  >([])
  const [tempOfferte, setTempOfferte] = useState<
    {
      attributes: ConfiguratorComponentOffertType
    }[]
  >([])

  const url =
    endpointUrl ??
    `${removeTrailingSlashes(
      process.env.A2A_DRUPAL_CASA_URL
    )}/jsonapi/offers_config_component/offers_config_component?page[limit]=1000`

  const getOfferte = () => {
    if (!url) {
      return
    }

    const myHeaders = new Headers()
    myHeaders.append('Referer', 'https://*.a2a.it')

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
    }

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.data) {
          consoleLog(res, res.data)
          setTempOfferte(res.data)
        }
      })
      .catch((err) => {
        consoleLog(err)
      })
  }

  useEffect(() => {
    getOfferte()
  }, [])

  useEffect(() => {
    if (tempOfferte && tempOfferte.length > 0) {
      const mapped = tempOfferte.map((item) => item.attributes)
      consoleLog(mapped)
      setOfferteData(mapped)
    }
  }, [tempOfferte])

  return (
    <StyledCommodityConfigWrapper>
      <StyledCommodityConfigInner>
        <StyledCommodityConfig
          $loading={!offerteData || (offerteData && offerteData.length <= 0)}
        >
          {offerteData && offerteData.length > 0 ? (
            <CommodityConfigMain
              offerteData={offerteData}
            ></CommodityConfigMain>
          ) : (
            <Loader />
          )}
        </StyledCommodityConfig>
      </StyledCommodityConfigInner>
    </StyledCommodityConfigWrapper>
  )
}
