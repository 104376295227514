import { ContainerStyle } from 'commons/Container'
import {
  BPSizes,
  Colors,
  ColumnsGap,
  Palette,
  Rem,
  SiteSpacings,
} from 'commons/Theme'
import styled, { css } from 'styled-components'
import { StyledAtomButton } from 'atoms/AtomButton.style'
import { StyledAtomLink } from 'atoms/AtomLink.style'
import { LoaderWrapper } from 'components/RicaricaForm/components/Loading'

const commonTextStyle = css`
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${Colors.blue800};
`

const smallHeaderTextStyle = css`
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${Colors.grey800};
`

export const StyledCommodityConfigInner = styled.div`
  position: relative;
`

export const StyledCommodityConfigTitle = styled.div<{
  $firstStep?: boolean
}>`
  ${({ $firstStep }) => ($firstStep ? commonTextStyle : smallHeaderTextStyle)};
  font-size: ${({ $firstStep }) => ($firstStep ? Rem(20) : Rem(16))};
`

export const StyledCommodityConfigHeader = styled.div`
  display: flex;
  gap: ${SiteSpacings.Spacing03.sm}px;
`

export const StyledCommodityConfigPrevChoice = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`

export const StyledCommodityConfigBack = styled.div`
  margin: auto;
  padding: 0 ${Rem(14)};
`

export const StyledCommodityConfigStepTitle = styled.div`
  ${commonTextStyle};
  font-size: ${Rem(24)};
  padding: ${SiteSpacings.Spacing04.sm}px 0;
`

export const StyledCommodityConfigStepOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SiteSpacings.Spacing04.sm}px;
`

export const StyledCommodityConfigStepOption = styled.div<{
  $isActive?: boolean
}>`
  background-color: ${({ $isActive }) =>
    $isActive ? Colors.azure800 : Colors.white};
  color: ${({ $isActive }) => ($isActive ? Colors.white : Colors.blue800)};
  padding: ${SiteSpacings.Spacing06.sm}px ${SiteSpacings.Spacing05.sm}px;
  border-radius: ${Rem(24)};
  border: 1px solid ${Colors.blue800};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  label {
    font-size: ${Rem(24)};
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    cursor: pointer;
  }

  p {
    padding-top: 4px;
    font-size: ${Rem(14)};
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    cursor: pointer;
  }
`

export const StyledConfiguratorComponentProgressBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${Colors.white};
`

export const StyledCommodityConfig = styled.div<{
  $loading?: boolean
}>`
  ${({ $loading }) =>
    $loading &&
    css`
      display: flex;
      min-height: 560px;
    `};

  width: 100%;

  background: ${Palette.Greyscale['white']};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

  ${StyledAtomButton} {
    width: 100%;
  }
  ${StyledAtomLink} {
    width: 100%;
  }
  ${LoaderWrapper} {
    margin: auto;
  }
`

export const StyledCommodityConfigContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Rem(24)};
  padding: ${SiteSpacings.Spacing05.sm}px;

  @media screen and (min-width: ${BPSizes.lg}px) {
    padding: ${SiteSpacings.Spacing05.lg}px;
  }
`

export const StyledCommodityConfigSteps = styled.div``

export const StyledCommodityConfigWrapper = styled.div`
  display: block;
  z-index: 2;

  @media screen and (min-width: 1200px) {
    ${ContainerStyle};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${ColumnsGap.lg};
  }

  ${StyledCommodityConfigInner} {
    @media screen and (min-width: 1200px) {
      grid-column: 9/-1;
      width: 100%;
    }
  }

  ${StyledCommodityConfig} {
    margin-top: ${SiteSpacings.Spacing05.sm}px;

    @media screen and (min-width: 1200px) {
      position: absolute;
      right: 0;
      z-index: 2;
      margin-top: ${SiteSpacings.Spacing09.lg}px;
    }
  }
`
