import { Container } from 'commons/Container'
import { Grid } from 'commons/Grid'
import styled from 'styled-components'
import { AtomButton } from '../../atoms/AtomButton'
import {
  BPSizes,
  Colors,
  HexTransparency,
  Palette,
  Rem,
  SiteSpacings,
  StyledHeadingH3,
  StyledParagraph,
  Typography,
} from '../../commons/Theme'
import { StyledProgressBarProps } from './Configuratore.types'

export const StyledConfiguratoreContainer = styled.div<{
  $showMobile: boolean
}>`
  min-height: 100vh;
  display: ${({ $showMobile }) => ($showMobile ? 'flex' : 'none')};
  flex-direction: column;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    display: flex;
  }
`

export const StyledConfiguratoreBodyWrapper = styled(Container)`
  position: relative;
  margin: 0px auto 120px;
  width: 100%;
  flex: 1;
`

export const StyledConfiguratoreOfferteWrapper = styled(Container)`
  position: relative;
  padding-top: 40px;
  width: 100%;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    padding-top: 80px;
  }
`

export const StyledConfiguratoreProgressBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${Colors.grey800 + HexTransparency[10]};
`

export const StyledConfiguratoreProgressBar = styled.div<StyledProgressBarProps>`
  height: 100%;
  background-color: ${Colors.azure800};
  width: calc(100% * ${({ $progress }) => $progress});
  transition: width 1s ease;
`

export const StyledConfiguratoreBackButton = styled.button`
  cursor: pointer;

  width: 56px;
  height: 56px;
  border-radius: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  stroke: ${Colors.blue800};

  &:hover {
    background-color: ${Colors.azure600};
    stroke: ${Colors.white};
    color: ${Colors.white};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    width: 56px;
    height: 56px;
    display: grid;
    grid-column: 2 / span 10;
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    top: 5px;
  }
`

export const StyledOffertaCard = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
`

export const StyledOffertaBody = styled.button`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  position: relative;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    padding: 24px;
  }
`

export const StyledOffertaTitle = styled.div`
  font-weight: 500;
  font-size: ${Rem(20)};
  color: ${Palette.Blue['800']};
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: left;
`

export const StyledOffertaSubtitle = styled.div`
  font-size: ${Rem(16)};
  color: ${Palette.Greyscale[800]};
  margin-bottom: 24px;
  text-align: left;
`

export const StyledOffertaIconsWrapper = styled.div`
  display: flex;
  gap: 24px;
`

export const StyledOffertaIcon = styled.div`
  border-radius: 999px;
  background-color: ${Colors.white};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledOffertaVantaggio = styled.div`
  border-bottom-left-radius: 24px 24px;
  border-top-right-radius: 2px 2px;
  background-color: ${Colors.pink};
  padding: 16px;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  font-weight: 500;
  font-size: ${Rem(14)};
  color: ${Colors.dark};
  @media screen and (min-width: ${BPSizes.tablet}px) {
    font-size: ${Rem(16)};
  }
`

export const StyledOffertaImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 193px;
  overflow: hidden;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    height: 187px;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    height: 166px;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    height: 229px;
  }
`

export const StyledOfferteWrapper = styled(Grid)`
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-bottom: 64px;
  }
`

export const StyledOffertaImage = styled.div<{ $src: string }>`
  background-image: url(${({ $src }) => $src});
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-position: center center;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.1);
  }
  width: 100%;
  height: 100%;
`

export const StyledGoBackLinksBackground = styled.div`
  padding: 100px 0px 80px;
  margin-top: -52px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    padding: 120px 0px 80px;
    margin-top: -120px;
  }
`

export const StyledGoBackLinks = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledGoBackLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    flex-direction: row;
  }
`

export const StyledSemiboldTitle = styled.div`
  ${StyledHeadingH3};
  color: ${Palette.Blue['800']};
`

export const StyledDescription = styled.div`
  ${StyledParagraph};
  margin-top: 16px;
  color: ${Palette.Blue['800']};
`

export const StyledNeedHelpMessage = styled(AtomButton)`
  margin-top: 48px;
`

export const StyledInfoBlock = styled.div`
  ${Typography.BodySmall.sm};
  color: ${Palette.Greyscale[800]};
  margin-bottom: ${SiteSpacings.Spacing05.md}px;

  @media screen and (min-width: ${BPSizes.md}px) {
    ${Typography.Paragraph.md};
    margin-bottom: ${SiteSpacings.Spacing07.md}px;
  }

  @media screen and (min-width: ${BPSizes.lg}px) {
    ${Typography.Paragraph.md};
  }

  h4 {
    ${Typography.Heading04.sm};
    line-height: 1.2;
    color: ${Palette.Blue['800']};
    margin-bottom: ${SiteSpacings.Spacing03.sm}px;

    @media screen and (min-width: ${BPSizes.md}px) {
      ${Typography.Heading04.md};
      margin-bottom: ${SiteSpacings.Spacing04.md}px;
    }

    @media screen and (min-width: ${BPSizes.lg}px) {
      ${Typography.Heading04.xlg};
      margin-bottom: ${SiteSpacings.Spacing05.lg}px;
    }
  }
`

export const StyledGoHomeLink = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    right: 0px;
    height: 50px;
  }
`

export const StyledConfiguratoreHeaderWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  width: 100%;
  height: auto;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin: 32px 0;
    display: grid;
    grid-column: 2 / span 10;
    justify-content: unset;
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    margin: 32px 0;
  }
`
