import Layout from 'Layout'
import { CommodityConfig } from 'components/CommodityConfig/CommodityConfig'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'

const TestConfiguratorePage = () => {
  return (
    <A2ADataContextProvider>
      <Layout noFooter pageTitle="Test Configuratore">
        <CommodityConfig />
      </Layout>
    </A2ADataContextProvider>
  )
}

export default TestConfiguratorePage
