import { StyledConfiguratoreProgressBar } from 'components/Configuratore/Configuratore.style'
import {
  StyledCommodityConfigBack,
  StyledCommodityConfigContent,
  StyledCommodityConfigHeader,
  StyledCommodityConfigPrevChoice,
  StyledCommodityConfigStepOption,
  StyledCommodityConfigStepOptionsWrapper,
  StyledCommodityConfigSteps,
  StyledCommodityConfigStepTitle,
  StyledCommodityConfigTitle,
  StyledConfiguratorComponentProgressBarWrapper,
} from '../CommodityConfig.style'
import { Icon } from 'components/Icons/Icon'
import { AtomButton } from 'atoms/AtomButton'
import { useEffect, useRef, useState } from 'react'
import {
  consoleLog,
  eventDataLayer,
  getGA4PageSezione,
  prepareStringForDataLayer,
} from 'commons/utils'
import { ArrowLeftIcon } from 'components/Icons/ArrowLeftIcon'
import { Colors } from 'commons/Theme'
import { AtomLink } from 'atoms/AtomLink'
import { Choice, Step, StepOption } from '../CommodityConfig.types'
import { configSteps } from '../CommodityConfigData'
import { GA4DataLayerType } from 'types/react'

export type ConfiguratorComponentOffertType = {
  prezzo_fisso: string | null
  processo_gas: string | null
  processo_luce: string | null
  tipologia_cliente: string | null
  vas: string | null
  status: boolean | null
  link: string | null
  label: string | null
  commodity: string | null
  [key: string]: string | number | boolean | null
}

const ProgressBar = ({
  activeStep,
  total,
}: {
  activeStep: number
  total: number
}) => {
  return (
    <StyledConfiguratorComponentProgressBarWrapper>
      <StyledConfiguratoreProgressBar
        $progress={(activeStep as number) / total}
      />
    </StyledConfiguratorComponentProgressBarWrapper>
  )
}

export const CommodityConfigMain = ({
  offerteData,
}: {
  offerteData: ConfiguratorComponentOffertType[]
}) => {
  const [filteredOffers, setFilteredOffers] = useState<
    ConfiguratorComponentOffertType[]
  >([])
  const [enoughOffers, setEnoughOffers] = useState<boolean>(true)
  const [offerte, setOfferte] = useState<ConfiguratorComponentOffertType[]>([])
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [finalSteps, setFinalSteps] = useState<Step[]>(configSteps)
  const [dataLayer, setDataLayer] = useState<GA4DataLayerType | null>(null)
  const [shadowStep, setShadowStep] = useState<boolean>(false)
  const buttonRef = useRef<null | HTMLButtonElement>(null)

  const getCurrentStep = () => {
    const result = finalSteps.find(
      (step: Step) => step.step === currentStep && step.confirmed
    )
    return result
  }

  const getNumberOfSteps = () => {
    const stepCount: number[] = finalSteps.map((step: Step) => step.step)
    return Math.max(...stepCount)
  }

  const handleFilterOffers = (steps: Array<Step>) => {
    const filtered = filteredOfferArray(steps)

    checkEnoughOffers(filtered)
    setFilteredOffers(filtered)
  }

  const filteredOfferArray = (steps: Array<Step>) => {
    const tempChoices: Choice[] = steps
      .filter(
        (step: Step) => step.confirmed === 'avoided' || step.confirmed === true
      )
      .reduce((filtered: Choice[], step: Step) => {
        const newOptions: Array<Choice> = step.options.map(
          (opt: StepOption) => {
            return {
              value: opt.value,
              stepName: step.name,
              selected: opt.selected,
            }
          }
        )
        const result = newOptions.filter((opt) => opt !== null)
        return filtered.concat(result)
      }, [])

    const choices = tempChoices.filter((choice: Choice) => choice.selected)

    let filtered: ConfiguratorComponentOffertType[] = [...offerte]
    const tipologiaUtente = getGA4PageSezione().toLowerCase()
    if (tipologiaUtente === 'casa' || tipologiaUtente === 'business') {
      filtered = filtered.filter(
        (offer) =>
          String(offer.tipologia_cliente).toLowerCase() === tipologiaUtente
      )
    } else {
      filtered = filtered.filter(
        (offer) => String(offer.tipologia_cliente).toLowerCase() === 'casa'
      )
    }
    const dataLayerTemp: GA4DataLayerType & { [key: string]: string } = {
      event: 'configuratore_commodity',
      sezione: getGA4PageSezione(),
    }

    choices.forEach((choice: Choice) => {
      if (choice.stepName === 'prezzo_fisso' && choice.value === '') {
        filtered = filtered?.filter(
          (offer) =>
            String(offer[choice.stepName]).toLowerCase() === '1_anno' ||
            String(offer[choice.stepName]).toLowerCase() === '10_anni'
        )
      } else if (choice.stepName === 'durata') {
        filtered = filtered?.filter((offer) => {
          return (
            String(offer['prezzo_fisso']).toLowerCase() ===
            choice.value?.toLowerCase()
          )
        })
      } else if (choice.value && choice.value !== '') {
        filtered = filtered?.filter((offer) => {
          return (
            String(offer[choice.stepName]).toLowerCase() ===
            choice.value?.toLowerCase()
          )
        })
      }

      const step = finalSteps.find(
        (step: Step) => step.name === choice.stepName
      )
      const opt = step?.options?.find((opt) => opt.value === choice.value)

      dataLayerTemp['stream'] = 'web'
      dataLayerTemp['step'] = prepareStringForDataLayer(step?.title)
      dataLayerTemp['label'] = prepareStringForDataLayer(opt?.label)
      if (String(step?.name).indexOf('commodity') > -1) {
        dataLayerTemp['type'] = prepareStringForDataLayer(opt?.value)
      }
      if (String(step?.name).indexOf('processo_') > -1) {
        dataLayerTemp['operazione'] = prepareStringForDataLayer(opt?.value)
      }
      if (String(step?.name).indexOf('prezzo_fisso') > -1) {
        dataLayerTemp['prezzo'] =
          opt?.value && opt.value !== ''
            ? prepareStringForDataLayer(opt?.value)
            : 'fissa'
      }
      if (String(step?.name).indexOf('durata') > -1) {
        dataLayerTemp['durata'] = prepareStringForDataLayer(opt?.value)
      }
      if (String(step?.name).indexOf('vas') > -1) {
        dataLayerTemp['altri_servizi'] = prepareStringForDataLayer(opt?.value)
      }

      removeNestedNullUndefined(dataLayerTemp)
    })

    setDataLayer(dataLayerTemp)

    return filtered
  }

  const removeNestedNullUndefined = (obj: { [key: string]: string | null }) => {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key]
      }
    }
  }

  const getFirstChoice = () => {
    if (finalSteps?.find((step: Step) => step.step === 0)) {
      const selectedOption = finalSteps
        ?.find((step: Step) => step.step === 0)
        ?.options?.filter((option: StepOption) => option.selected)?.[0]
      return selectedOption
    }
  }

  const handleOptionChoice = (opt?: StepOption) => {
    const option = opt ?? getCurrentStep()?.options?.[0] ?? null
    const nextStepName = getCurrentStep()?.next
      ? String(getCurrentStep()?.next).replace('$value$', option?.value ?? '')
      : String(option?.next).replace('$value$', option?.value ?? '')

    const newArray = finalSteps.map((step: Step) => {
      if (step.step === currentStep) {
        step.options.map((opt: StepOption) => {
          opt.selected = opt.value === option?.value
          return opt
        })
      }
      step.confirmed =
        step.confirmed === 'avoided'
          ? 'avoided'
          : step.confirmed === 'pending' && step.name !== nextStepName
            ? false
            : step.name === nextStepName
              ? 'pending'
              : !!step.confirmed
      return step
    })
    buttonRef.current?.blur()
    setFinalSteps(newArray)
    handleFilterOffers(newArray)
  }

  const goToNextStep = () => {
    const newStep = shadowStep ? currentStep + 2 : currentStep + 1
    setCurrentStep(newStep)
    const newArray = finalSteps.map((step) => {
      step.confirmed =
        step.confirmed === 'avoided'
          ? 'avoided'
          : step.confirmed === 'pending'
            ? true
            : !!step.confirmed
      return step
    })

    setFinalSteps(newArray)
  }

  const offerNotAvailable = () => {
    return (
      (getCurrentStep()?.next ||
        getCurrentStep()?.options.find((option) => option.next)) &&
      enoughOffers
    )
  }

  const checkEnoughOffers = (offers: ConfiguratorComponentOffertType[]) => {
    const nextOptions = getCurrentStep()
      ?.options?.filter((option: StepOption) => option.selected)
      .find((option: StepOption) => option.next)

    let nextStep = ''
    if (currentStep === 0) {
      const commodity = getFirstChoice()?.value
      nextStep = String(
        getCurrentStep()?.next ?? nextOptions?.next ?? ''
      ).replace('$value$', commodity ?? '')
    } else {
      nextStep = getCurrentStep()?.next ?? nextOptions?.next ?? ''
    }

    let unique: Array<string | boolean | number | null> = []

    if (nextStep === 'durata') {
      const doubleFiltered = offers.filter(
        (offer) => offer.prezzo_fisso !== 'variabile'
      )
      unique = [...new Set(doubleFiltered.map((item) => item['prezzo_fisso']))]
    } else {
      unique = [...new Set(offers.map((item) => item[nextStep]))]
    }

    const onlyFisso =
      unique &&
      unique.length >= 1 &&
      unique.length < 2 &&
      (unique.includes('1_anno') || unique.includes('10_anni')) &&
      !unique.includes('variabile')
    const onlyVariabile =
      unique && unique.length === 1 && unique.includes('variabile')

    if (nextStep === 'prezzo_fisso' && onlyFisso) {
      // GO TO DURATA

      const prezzoFissoStep = finalSteps.find(
        (step) => step.name === 'prezzo_fisso'
      )
      const durataStep = finalSteps.find((step) => step.name === 'durata')
      if (prezzoFissoStep) {
        prezzoFissoStep.confirmed = 'avoided'
        prezzoFissoStep.options?.map((option) => {
          option.selected = option.value === ''
        })
      }
      if (durataStep) {
        durataStep.confirmed = 'pending'
      }
      setShadowStep(true)
    } else if (nextStep === 'prezzo_fisso' && onlyVariabile) {
      // GO TO VAS

      const prezzoFissoStep = finalSteps.find(
        (step) => step.name === 'prezzo_fisso'
      )
      const vasStep = finalSteps.find((step) => step.name === 'vas')
      if (prezzoFissoStep) {
        prezzoFissoStep.confirmed = 'avoided'
        prezzoFissoStep.options?.map((option) => {
          option.selected = option.value === 'variabile'
        })
      }
      if (vasStep) {
        vasStep.confirmed = 'pending'
      }
      setShadowStep(true)
    } else {
      setShadowStep(false)
      setEnoughOffers(unique.length > 1)
    }
  }

  useEffect(() => {
    handleOptionChoice()
  }, [currentStep])

  useEffect(() => {
    if (offerteData && offerte.length === 0) {
      const newO = offerteData.map((off) => {
        return {
          ...off,
          processo_dual: off.processo_luce,
        }
      })
      checkEnoughOffers(newO)
      setOfferte(newO)
    }
  }, [offerteData, offerte])

  return (
    <>
      <ProgressBar activeStep={currentStep} total={getNumberOfSteps()} />
      <StyledCommodityConfigContent>
        <StyledCommodityConfigHeader>
          {currentStep > 0 && (
            <StyledCommodityConfigBack>
              <Icon
                width="12px"
                desktopWidth="14px"
                Icon={ArrowLeftIcon}
                stroke={Colors.blue800}
                onClick={() => {
                  const previousStep = finalSteps.find(
                    (step) => step.step === currentStep - 1
                  )

                  const newStep =
                    previousStep?.confirmed === 'avoided'
                      ? currentStep - 2
                      : currentStep - 1

                  const newArray = finalSteps.map((step) => {
                    step.confirmed =
                      step.confirmed === 'avoided'
                        ? 'pending'
                        : step.confirmed && step.step < currentStep
                    return step
                  })
                  setFinalSteps(newArray)
                  setCurrentStep(newStep)
                }}
              />
            </StyledCommodityConfigBack>
          )}
          <div style={{ flexGrow: 1 }}>
            <StyledCommodityConfigTitle $firstStep={currentStep === 0}>
              Trova la tua offerta
              {currentStep > 0 && ` | ${currentStep} di ${getNumberOfSteps()}`}
            </StyledCommodityConfigTitle>
            {currentStep > 0 && (
              <StyledCommodityConfigPrevChoice>
                {getFirstChoice()?.label}
              </StyledCommodityConfigPrevChoice>
            )}
          </div>
        </StyledCommodityConfigHeader>
        <StyledCommodityConfigSteps>
          <StyledCommodityConfigStepTitle>
            {getCurrentStep()?.title}
          </StyledCommodityConfigStepTitle>
          <StyledCommodityConfigStepOptionsWrapper>
            {getCurrentStep()?.options &&
              getCurrentStep()?.options?.map(
                (option: StepOption, index: number) => (
                  <StyledCommodityConfigStepOption
                    role="button"
                    onClick={() => handleOptionChoice(option)}
                    $isActive={option.selected}
                    key={index}
                  >
                    <label>{option.label}</label>
                    {option.subtext && <p>{option.subtext}</p>}
                  </StyledCommodityConfigStepOption>
                )
              )}
          </StyledCommodityConfigStepOptionsWrapper>
        </StyledCommodityConfigSteps>
        {offerNotAvailable() ? (
          <AtomButton
            ref={buttonRef}
            label={'Continua'}
            styleType="primary"
            onClick={() => {
              consoleLog(
                '%cOfferte residue',
                'background: #087908; color: #ffffff; font-weight: bold; padding: 2px 4px; border-radius: 3px; font-size: 10px;',
                filteredOffers
              )
              goToNextStep()
            }}
            onDataLayer={() => {
              dataLayer && eventDataLayer(dataLayer)
            }}
          />
        ) : (
          <AtomLink
            styleType="primary"
            to={filteredOffers[0]?.link ?? ''} // go to offer
            label={'Continua'}
          />
        )}
      </StyledCommodityConfigContent>
    </>
  )
}
